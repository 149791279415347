@font-face {
  font-family: "AT Surt";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/atsurt-light-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "AT Surt";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/atsurt-regular-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "AT Surt";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/atsurt-demibold-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "AT Surt";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/atsurt-semibold-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "AT Surt";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/atsurt-bold-webfont.woff2") format("woff2");
}

* {
  box-sizing: border-box;
}

html,
body {
  display: block;
  height: 100%;
  width: 100%;
}

html {
  background-color: rgb(250 250 250);
  color: var(--text-default);
  font-family: "AT Surt",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

body {
  line-height: 1.43;
}

#App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ContentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.ContextMenuIcon {
  box-sizing: content-box;
  color: var(--neutral-medium);
  width: 0.5ch;
}

.ContextMenuIcon[variant="glyph"] {
  padding: unset !important;
}

.ContextMenuIcon::before {
  content: '…';
  display: inline-block;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1;
  rotate: 90deg;
  text-align: center;
  vertical-align: middle;
}

.ContextMenuIcon:hover {
  color: var(--brand-accent-primary);
}

.ContextMenuIcon[variant="glyph"]::before {
  font-size: 1.25em;
}

.ListPage {
  gap: var(--grid-gutter);
  padding: calc(2 * var(--grid-gutter));
}

.SplitContent {
  align-items: start;
}

.EvenSplit {
  flex: 1;
}

.SplitContent,
.SplitHeader {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.SplitHeader {
  align-items: center;
}

@media (width <= 960px) {
  .StackableSplit {
    align-items: stretch;
    display: flex;
    flex-direction: column;
  }
}

@media print {
  .ContentContainer {
    overflow-y: initial;
  }
}

[hidden] {
  display: none !important;
}

a {
  color: var(--brand-accent-primary);
  text-decoration: none;
}

a[target="_blank"] {
  align-items: flex-start;
  display: inline-flex;
  gap: 0.3ch;
}

a[target="_blank"]::after {
  content: "⇱";
  display: inline-block;
  font-size: 0.8em;
  margin-top: -0.4em;
  rotate: 90deg;
}

a[target="_blank"].icon {
 color: var(--neutral-medium);
 cursor: pointer;
 font-size: 12px;
 line-height: 1em;
}

a[target="_blank"][guise="Button"][variant="glyph"].icon {
  color: var(--neutral-medium);
}

a[target="_blank"].icon:hover,
a[target="_blank"][guise="Button"][variant="glyph"].icon:hover {
  color: var(--brand-accent-primary-hover);
}

a[target="_blank"].icon::after {
  content: ' '
}

button {
  cursor: pointer;
  padding: 0;
  white-space: nowrap;
}

button[disabled],
button:disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

em {
  font-style: italic;
}

h1 {
  font-size: 1.5em;
}

h1,
h2,
h3 {
  font-weight: bold;
}

h2 {
  color: var(--brand-dark);
  font-size: 1.2em;
}

h3 {
  color: var(--text-neutral);
  font-size: 1.1em;
}

h4 {
  font-weight: 600;
}

img,
object {
  max-width: 100%;
}

label,
legend {
  font-weight: 500;
}

input[type="date"],
input[type="datetime-local"],
input[type="time"] {
  flex-direction: row-reverse;
  gap: 0.5em;
}

button,
input,
select,
textarea {
  font-family: inherit;
}

kbd {
  background-color: var(--brand-light);
  border: 1px solid var(--border-colour-normal);
  border-radius: 0.4em;
  color: var(--text-neutral);
  font-family: monospace;
  padding: 0.2em 0.4em;
}

pre {
  white-space: pre-line;
}

strong {
  font-weight: bold;
}

time {
  white-space: nowrap;
}

/* stylelint-disable selector-class-pattern */
.bulleted {
  list-style: initial;
  margin-left: 1.5em;
}

ol.bulleted {
  list-style-type: decimal;
}

ul.bulleted {
  list-style-type: disc;
}

.bulleted > li {
  margin: 0.5em 0;
}

.code {
  font-family: monospace;
}

.muted {
  color: var(--text-muted) !important;
}

.note {
  font-size: 0.8em;
}

.inline-list,
.inline-list > li {
  display: inline;
}

.inline-list > li + li::before {
  content: ', ';
}

.warning {
  color: var(--status-danger);
  font-weight: bold;
}

.Card {
  background-color: var(--brand-light);
  box-shadow: var(--box-shadow);
}

.Card,
.Well {
  border-radius: var(--border-radius);
  padding: var(--grid-gutter);
}

.Well {
  background-color: var(--brand-subtle-light);
  border: 1px solid var(--border-colour-normal);
}

.ColorPicker {
  box-sizing: content-box;
  height: 2em;
  width: 2em;
}

.HollowWrapper {
  display: contents;
}

.SectionIcon {
  height: 1em;
}

[status="DRAFT"] {
  background: var(--engagement-status-draft);
  color: var(--neutral-dark);
}

[status="REVIEW"] {
  background: var(--engagement-status-review);
}

[status="SCHEDULED"] {
  background: var(--engagement-status-scheduled);
}

[status="OPTINSOPEN"] {
  background: var(--engagement-status-optins);
}

[status="MATCHING"] {
  background: var(--engagement-status-matching);
}

[status="MATCHED"] {
  background: var(--engagement-status-matched);
}

[status="MEETING"] {
  background: var(--engagement-status-meeting);
}

[status="COMPLETED"] {
  background: var(--engagement-status-completed);
}

[status="REVIEW"],
[status="SCHEDULED"],
[status="OPTINSOPEN"],
[status="MATCHING"],
[status="MATCHED"],
[status="MEETING"],
[status="COMPLETED"] {
  color: var(--brand-light);
}

textarea:disabled {
  background: var(--neutral-lighter) !important;
}
